enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  REFRESH_ACCOUNT = "refreshAccount",
  REFRESH_USER_LOCATIONS = "refreshUserLocations",
  REFRESH_ACCOUNT_LOCATIONS = "refreshAccountLocations",
  REFRESH_LOCATION_TV_LISTINGS = "refreshLocationTVListings",
  REFRESH_ACCOUNT_SUBSCRIPTIONS = "refreshAccountSubscriptions",
  REFRESH_LOCATION_EVENTS = "refreshLocationEvents",
  REFRESH_ACCOUNT_USERS = "refreshAccountUsers",
  REFRESH_PAYMENT_METHODS = "refreshPaymentMethods",
  REFRESH_ENABLED_MARKETING_PROGRAMS = "refreshEnabledMarketingPrograms",
  REFRESH_IMAGES = "refreshImages",
  REMOVE_PAYMENT_METHOD = "removePaymentMethods",
  REFRESH_PLANS = "refreshPlans",
  REFRESH_THEMES = "refreshThemes",
  SEARCH_PLANS = "searchPlans",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_LOCATIONS = "setLocations",
  SET_SUBSCRIPTIONS = "setSubscriptions",
  SET_LOADING_LABEL = "setLoadingLabel",

  SET_TV_LISTINGS = "setTVListings",
  SET_EVENTS = "setEvents",
  SET_SELECTED_LOCATION = "setSelectedLocation",
  SET_USER_LOCATIONS = "setUserLocations",
  SET_PAYMENT_METHODS = "setPaymentMethods",
  SET_PLANS = "setPlans",
  SET_THEMES = "setThemes",
  SET_ACCOUNT = "setAccount",
  SET_ACCOUNT_USERS = "setAccountUsers",
  SET_ENABLED_MARKETING_PROGRAMS = "setEnabledMarketingPrograms",
  SET_ACCOUNT_IMAGES = "setAccountImages",
  SET_ACCOUNT_IMAGE = "setAccountImage",
  DELETE_ACCOUNT_IMAGE = "deleteAccountImage",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
}

const ContactChoices = [2500, 5000, 10000, 15000, 20000];

const JobDescriptions = {
  0: "Other",
  1: "General Manager",
  2: "Regional Manager",
  3: "Assistant GM",
  4: "Kitchen Manager",
  5: "Marketing Manager",
  6: "Shift Manager",
};

const PlanCategories = {
  CONTACT_BASED: 100,
  LOCATION_BASED: 200,
};

const AccountFeatures = {
  BASIC_EMAIL_SERVICE: "BASIC EMAIL SERVICE",
  PAID_PROMOTIONS: "PAID PROMOTIONS",
  FREE_PROMOTIONS: "FREE PROMOTIONS",
  SEGMENTATION: "SEGMENTATION",
  REPORTING: "REPORTING",
  AB_SENDING: "AB SENDING",
  SPORTS_TV_GUIDE: "Sports TV Guide",
  LOCATION_ACCESS: "Location Access",
  CONTACT_ACCESS: "Contact Access",
  WORKFLOW_AUTOMATIONS: "WORKFLOW AUTOMATIONS",
  PRESET_RECURRING_CAMPAIGNS: "PRESET RECURRING CAMPAIGNS",
  LANDING_PAGES: "LANDING PAGES",
};

export {
  Actions,
  Mutations,
  ContactChoices,
  JobDescriptions,
  PlanCategories,
  AccountFeatures,
};
