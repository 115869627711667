import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/sbmx/dashboard/Dashboard.vue"),
      },
      {
        path: "/marketingprograms",
        name: "marketing-programs-overview",
        component: () =>
          import("@/views/sbmx/marketingprograms/MarketingProgramBase.vue"),
        children: [
          {
            path: "overview",
            name: "marketing-programs-overview",
            component: () =>
              import(
                "@/views/sbmx/marketingprograms/MarketingProgramsOverview.vue"
              ),
          },
        ],
      },
      {
        path: "/medialibrary",
        name: "media-library-overview",
        component: () =>
          import("@/views/sbmx/medialibrary/MediaLibraryBase.vue"),
        children: [
          {
            path: "overview",
            name: "media-library-overview",
            component: () =>
              import("@/views/sbmx/medialibrary/MediaLibraryOverview.vue"),
          },
        ],
      },
      {
        path: "/onboard",
        name: "onboard",
        component: () => import("@/views/sbmx/onboard/Onboard.vue"),
        children: [
          {
            path: "selectplan",
            name: "onboard-select-plan",
            component: () => import("@/views/sbmx/onboard/SelectPlan.vue"),
          },
          {
            path: "addlocations",
            name: "onboard-add-locations",
            component: () => import("@/views/sbmx/onboard/AddLocations.vue"),
          },
        ],
      },
      {
        path: "/reporting",
        name: "reporting",
        component: () => import("@/views/sbmx/reporting/Reporting.vue"),
      },
      {
        path: "/account",
        name: "account-overview",
        component: () => import("@/views/sbmx/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/sbmx/account/Overview.vue"),
          },
          {
            path: "billing",
            name: "account-billing",
            component: () => import("@/views/sbmx/account/Billing.vue"),
          },
          {
            path: "plan",
            name: "account-plan",
            component: () => import("@/views/sbmx/account/Plan.vue"),
            meta: {
              noSubscription: true,
              noLocations: true,
            },
          },
          {
            path: "users",
            name: "account-users",
            component: () => import("@/views/sbmx/account/Users.vue"),
          },
          {
            path: "locations",
            name: "account-locations",
            component: () => import("@/views/sbmx/account/Locations.vue"),
            meta: {
              noLocations: true,
            },
          },
          {
            path: "branding",
            name: "account-branding",
            component: () => import("@/views/sbmx/account/Branding.vue"),
          },
        ],
      },
      {
        path: "/manage_widgets",
        name: "manage-widgets",
        component: () =>
          import("@/views/sbmx/manage_widgets/ManageWidgetBase.vue"),
        children: [
          {
            path: "tv_guide",
            name: "manage-widgets-tv_guide",
            component: () =>
              import("@/views/sbmx/manage_widgets/tv_guide/TVGuideBase.vue"),
          },
          {
            path: "events",
            name: "manage-widgets-events",
            component: () =>
              import("@/views/sbmx/manage_widgets/events/EventBase.vue"),
          },
        ],
      },
      {
        path: "/contacts",
        name: "manage-contacts",
        component: () => import("@/views/sbmx/contacts/ManageContactsBase.vue"),
        children: [
          {
            path: "import",
            name: "manage-contacts-import",
            component: () =>
              import("@/views/sbmx/contacts/import/ImportContacts.vue"),
          },
          {
            path: "search",
            name: "manage-contacts-search",
            component: () =>
              import("@/views/sbmx/contacts/search/SearchContacts.vue"),
          },
        ],
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/sbmx/profile/Profile.vue"),
      },
      {
        path: "/support",
        name: "support",
        component: () => import("@/views/sbmx/support/Support.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/sbmx/authentication/SignIn.vue"),
        meta: {
          public: true,
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/sbmx/authentication/SignUp.vue"),
        meta: {
          public: true,
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/sbmx/authentication/PasswordReset.vue"),
        meta: {
          public: true,
        },
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/sbmx/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.meta.public !== true) {
    store.dispatch(Actions.VERIFY_AUTH);

    if (to.meta.noSubscription !== true) {
      if (store.state.AuthModule.account.id !== null) {
        if (store.state.AuthModule.account.locationLimit == 0) {
          router.replace({ name: "account-plan" });
        }
      }
    }

    if (to.meta.noLocations !== true) {
      if (store.state.AuthModule.account.id !== null) {
        if (store.state.AuthModule.account.numberOfLocations == 0) {
          router.replace({ name: "account-locations" });
        }
      }
    }
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
