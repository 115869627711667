
import { defineComponent, nextTick, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const router = useRouter();

    watch(
      () => store.state.AuthModule.account.id,
      () => {
        store.dispatch(Actions.REFRESH_ACCOUNT_LOCATIONS);
        store.dispatch(Actions.REFRESH_PAYMENT_METHODS);
        store.dispatch(Actions.REFRESH_ACCOUNT_USERS);
        store.dispatch(Actions.REFRESH_PLANS);
        store.dispatch(Actions.REFRESH_ACCOUNT_SUBSCRIPTIONS);
        store.dispatch(Actions.REFRESH_THEMES);
        store.dispatch(Actions.REFRESH_ENABLED_MARKETING_PROGRAMS);
        store.dispatch(Actions.REFRESH_IMAGES);
        if (store.state.AuthModule.isAuthenticated) {
          if (
            "subscriptions" in store.state.AuthModule.account &&
            store.state.AuthModule.account.subscriptions.length == 0
          ) {
            router.push({ name: "onboard-select-plan" });
          }
        }
      }
    );
    watch(
      () => store.state.AuthModule.selectedLocation.id,
      () => {
        store.dispatch(Actions.REFRESH_LOCATION_EVENTS);
      }
    );

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      if (store.state.AuthModule.isAuthenticated == false) {
        router.push({ name: "sign-in" });
      }

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
